<template>
  <div>
    <banner
      v-if="$store.getters['app/banners'].all_shops_page.img"
      :loading="false"
      :banner="$store.getters['app/banners'].all_shops_page"
      class="mb-5"
    />

    <v-container class="py-6">
      <v-row align="center" class="mb-3">
        <v-col cols="12" sm>
          <h1 class="fs-21 text-primary">{{ $t('all_shops') }}</h1>
        </v-col>
        <v-col cols="12" md="auto" lg="4" sm="12">
          <v-form
            class="border rounded flex-grow-1"
            @submit.stop.prevent="pageSwitch(1)"
          >
            <v-row align="center" dense>
              <v-col>
                <v-text-field
                  :placeholder="$t('shop_name')"
                  type="text"
                  hide-details="auto"
                  class=""
                  v-model.trim="shopName"
                  required
                  solo
                  flat
                ></v-text-field>
              </v-col>
              <v-col cols="auto me-1" class="d-md-block">
                <v-btn
                  block
                  color="primary"
                  elevation="0"
                  :disabled="isDisabled"
                  @click.stop.prevent="pageSwitch(1)"
                >
                  {{ $t('search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <!-- <v-col cols="6" sm="12" md="auto" >
          <v-autocomplete
            v-model="selectedCategoryId"
            :items="allCategories"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            clear-icon="la-times fs-14"
            append-icon="la-angle-down fs-14"
            :placeholder="$t('sort_by_category')"
            :no-data-text="$t('no_data_available')"
            flat
            solo
            outlined
            hide-details
            clearable
            @change="categoryChange"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" sm="12" md="auto">
          <v-autocomplete
            v-model="selectedBrandId"
            :items="allBrands"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            clear-icon="la-times fs-14"
            append-icon="la-angle-down fs-14"
            :placeholder="$t('sort_by_brand')"
            :no-data-text="$t('no_data_available')"
            flat
            solo
            outlined
            hide-details
            clearable
            @change="brandChange"
          >
          </v-autocomplete>
        </v-col> -->
      </v-row>
      <v-row class="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        <v-col v-for="(shop, i) in allShops" :key="i">
          <shop-box :shop-details="shop" :is-loading="loading" />
        </v-col>
      </v-row>

      <p v-if="!loading && !allShops.length" class="pa-4 text-center fs-20">
        {{ $t('not_find_shop') }}
      </p>

      <div
        class="d-flex justify-center"
        v-if="totalPages > 1 && allShops.length"
        style="align-items: center; flex-wrap: wrap"
      >
        <v-pagination
          v-model="queryParam.page"
          class="my-4"
          :length="totalPages"
          prev-icon="la-angle-left"
          next-icon="la-angle-right"
          :total-visible="7"
          elevation="0"
          @input="pageSwitch"
        ></v-pagination>
        <input
          v-model="searchPage"
          :placeholder="queryParam.page"
          type="number"
          :name="$t('payment_option')"
          style="
            border: 1px solid #ececec;
            height: 32px;
            width: 60px;
            text-align: center;
            margin-right: 10px;
            border-radius: 4px;
          "
        />
        <v-btn color="primary" small @click.stop.prevent="onSearchPage">
          {{ $t('search') }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import ShopBox from '../../components/shop/ShopBox.vue'
export default {
  data: () => ({
    loading: true,
    totalPages: 1,
    allCategories: [],
    allBrands: [],
    allShops: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    queryParam: {
      page: 1,
      categoryId: null,
      brandId: null
    },
    selectedCategoryId: null,
    selectedBrandId: null,

    shopName: '',
    searchPage: ''
  }),
  components: {
    ShopBox
  },
  computed: {
    isDisabled() {
      if (!this.shopName) {
        return true
      }
      return !this.shopName
    }
  },
  methods: {
    onSearchPage() {
      this.pageSwitch(this.searchPage)
    },

    async getBrands() {
      const res = await this.call_api('get', 'all-brands')
      if (res.data.success) {
        this.allBrands = res.data.data
        this.selectedBrandId = this.allBrands.some(
          (brand) => brand.id == this.$route.query.brandId
        )
          ? this.allBrands.find(
              (brand) => brand.id == this.$route.query.brandId
            ).id
          : null
      }
    },
    async getCategories() {
      const res = await this.call_api('get', 'categories/first-level')
      if (res.data.success) {
        this.allCategories = res.data.data
        this.selectedCategoryId = this.allCategories.some(
          (category) => category.id == this.$route.query.categoryId
        )
          ? this.allCategories.find(
              (category) => category.id == this.$route.query.categoryId
            ).id
          : null
      }
    },
    categoryChange(id) {
      this.queryParam.categoryId = id
      this.queryParam.page = 1
      this.$router
        .push({
          query: {
            ...this.$route.query,
            categoryId: this.queryParam.categoryId,
            page: this.queryParam.page
          }
        })
        .catch(() => {})
      this.getShops({ categoryId: id })
    },
    brandChange(id) {
      this.queryParam.brandId = id
      this.$router
        .push({
          query: {
            ...this.$route.query,
            brandId: this.queryParam.brandId,
            page: this.queryParam.page
          }
        })
        .catch(() => {})
      this.getShops({ brandId: id })
    },
    pageSwitch(pageNumber) {
      if (!this.shopName && pageNumber == 1) return

      this.$router
        .push({
          query: {
            ...this.$route.query,
            page: pageNumber,
            shopName: this.shopName
          }
        })
        .catch(() => {})
      this.getShops({ page: pageNumber })
    },
    async getShops(obj) {
      this.loading = true
      const params = { ...this.queryParam, ...obj }

      let url = 'all-shops?'
      url += `&page=${params.page}`
      url += this.shopName ? `&shop_name=${this.shopName}` : ''
      url += params.categoryId ? `&category_id=${params.categoryId}` : ''
      url += params.brandId ? `&brand_id=${params.brandId}` : ''

      const res = await this.call_api('get', url)
      if (res.data.success) {
        this.allShops = res.data.data
        this.totalPages = res.data.meta.last_page
        this.queryParam.page = Number(res.data.meta.current_page)
        this.loading = false
      }
    }
  },

  watch: {
    $route(newVal) {
      console.log('rrrr', newVal.query.shopName)
      this.shopName = newVal.query.shopName
      this.queryParam.page = Number(newVal.query.page)

      this.getShops({ page: this.queryParam.page, shopName: this.shopName })
    }
  },
  created() {
    this.getCategories()
    this.getBrands()

    this.queryParam.page =
      Number(this.$route.query.page) || this.queryParam.page
    this.queryParam.brandId =
      this.$route.query.brandId || this.queryParam.brandId
    this.queryParam.categoryId =
      this.$route.query.categoryId || this.queryParam.categoryId

    this.shopName = this.$route.query.shopName || this.queryParam.shopName

    this.getShops({
      page: this.queryParam.page,
      brandId: this.queryParam.brandId,
      categoryId: this.queryParam.categoryId
    })
  }
}
</script>
